<template>
  <div style="background-color: #F7F7F7;">
    <div class="title_div">
      <div style="height:6vh;line-height:12vh;font-size:4.6vw;display:flex;justify-content:center">
        <div v-if="user_type2">区域看板</div>
        <div v-else>{{pagetype==1?'总部看板':'门店看板'}}</div>
      </div>
      <div style="display:flex;justify-content:flex-end;font-size:3.5vw;margin-right:3vw;">
        <div>{{new_show_date}}</div>
      </div>
    </div>
    <!--列表1 dashed-->
    <div style="display:flex;margin-top:2vh;justify-content:center;">
      <div style="display:flex;height:12vh;align-items:center;background-color:#FFFFFF;">
        <div v-for="(item,index) in table_data1" :key="index" class="every_option1" :style="index<3?'border-right: 1px solid rgba(204, 204, 204, 0.3);':''">
          <img :src="item?item.img:''" style="height:6vw;width:6vw;">
          <div style="font-size:3.6vw;margin-top:0.6vh;">{{item?item.name:''}}</div>
          <div style="font-size:3vw;margin-top:0.6vh;color:#898989;">{{item?item.value:''}}</div>
        </div>
      </div>
    </div>
    
    <!--时间-->
    <div style="display:flex;justify-content:center;margin-top:1.4vh;">
      <div style="display:flex;height:6vh;align-items:center;background-color:#FFFFFF;">
        <div  v-for="(item,index) in time_item" :key="index" class="every_time" @click="chooseTime(index)" :style="index<3?'border-right: 1px solid rgba(204, 204, 204, 0.3);':''">
          <img v-if="time_value!=index" :src="item.icon" style="height:5.2vw;width:5.2vw;">
          <img v-else :src="item.icon_xuan" style="height:5.2vw;width:5.2vw;">
          <div class="time_choose" :style="time_value==index?'color:#2163E5;':''">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!--销售汇总区-->
    <div style="display:flex;margin-top:1.6vh;align-items:center;margin-left:5vw;margin-bottom:1vh;">
        <img src="@/assets/icon/xiaoshouhuizong.png" class="option_img">
        <div class="option_title">销售汇总区</div>
    </div>
    <!--列表2-->
     <div style="display:flex;flex-wrap:wrap;margin-left:4vw;">
        <div v-for="(item,index) in table_data2" :key="index" class="every_option2" :style="(index+1)%3!=0?'margin-right:1vw;':''">
            <!-- <div class="option2_font">同比:↑0.64 &nbsp;&nbsp; 环比:↑0.72</div> -->
            <div style="font-weight:800;font-size:3.6vw;margin-left:1vw;margin-top:1vh;text-align:center;">{{item?item.name:''}}</div>
            <div style="display:flex;display:flex;justify-content:space-between;margin-top:0.4vh;margin:1vh 0;">
              <div class="option2_font2">{{item?item.value:''}}</div>
              <img :src="item?item.img:''" style="height:6.6vw;width:6.6vw;margin-right:2vw;">
            </div>
        </div>
    </div>
    <!--销售汇总区-->
    <div style="display:flex;margin:1.6vh;align-items:center;margin-left:5vw;">
        <img src="@/assets/icon/xiaoshoue.png" class="option_img">
        <div class="option_title">{{pagetype==2?'营业员':''}}销售额示意图</div>
    </div>
    <!--图表-->
    <div style="display:flex;justify-content:center;">
      <!-- 总店图 -->
      <div v-if="pagetype==1" class="line_body" :style="'width:'+(200 + line_data.length*22)+'px;'">
        <LineChart id="zongbu" :table_data="line_data"></LineChart>
      </div>
      <!-- 门店图 -->
      <div v-if="pagetype==2" class="line_body" :style="'width:'+(200 + line_data.length*22)+'px;'">
        <LineChart id="mendian" :table_data="line_data"></LineChart>
      </div>
      <!-- 区域图(一天) -->
      <!-- <div v-if="pagetype==2&&user_type2&&(time_value==0||time_value==1)" class="line_body" style="width:360vw;">
        <LineChart id="360" :table_data="line_data"></LineChart>
      </div> -->
      <!-- 区域图(一周以上) -->
      <!-- <div v-if="pagetype==2&&user_type2&&(time_value==2||time_value==3)" class="line_body" style="width:360vw;">
        <LineChart id="360" :table_data="line_data"></LineChart>
      </div> -->
    </div>
    <!--门店排名-->
    <div style="display:flex;justify-content:center;margin-top:2vh;">
      <div style="background-color:#FFFFFF;width:92vw;">
        <div style="display:flex;align-items:center;margin-left:2vw;height:6vh;">
          <img src="@/assets/icon/mendianpaiming.png" class="option_img">
          <div class="option_title">{{pagetype==1?'门店排名':'营业员销售排名'}}</div>
        </div>
        <div style="margin-left:2vw;overflow:scroll;">
          <div style="display:flex;color:#9C9C9C;font-size:3.2vw;margin-bottom:0.5vh;">
            <div style="min-width:10vw;text-align:center;">排名</div>
            <!-- <div v-for="(item,index) in table_data3[0]" :key="index" style="width:30vw;text-align:center;">{{index}}</div> -->
            <div v-if="pagetype==1" style="width:40vw;text-align:center;">门店</div>
            <div v-if="pagetype==2" style="width:40vw;text-align:center;">营业员</div>
            <div style="width:30vw;text-align:center;">销售额</div>
            <div v-if="pagetype==2" style="width:40vw;text-align:center;">会员销售额</div>
            <div v-if="pagetype==2" style="width:30vw;text-align:center;">客单价</div>
            <div v-if="pagetype==2" style="width:30vw;text-align:center;">客流量</div>
          </div>
          
          <div v-for="(item,index) in table_data3" :key="index" style="display:flex;color:#9C9C9C;font-size:3vw;border-top:1px solid #E2E2E2;">
            <div v-if="index<3" style="min-width:10vw;border-left:1px solid #E2E2E2;display:flex;justify-content:center;align-items:center;">
              <img v-if="index==0" src="@/assets/icon/paiming1.png" class="ranking_icon">
              <img v-if="index==1" src="@/assets/icon/paiming2.png" class="ranking_icon">
              <img v-if="index==2" src="@/assets/icon/paiming3.png" class="ranking_icon">
            </div>
            <div  v-if="index>2&&index<table_data3.length-1" style="min-width:10vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{index + 1}}</div>
            <div  v-if="index==table_data3.length-1" style="min-width:10vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;"></div>
            <!-- <div  v-for="(item2,index2) in item" :key="index2"  style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item2}}</div> -->
            <div v-if="pagetype==1" style="width:40vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.分支机构编码}}</div>
            <div v-if="pagetype==2" style="width:40vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.营业员}}</div>
            <div  style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.销售额?item.销售额:''}}</div>
            <div v-if="pagetype==2" style="width:40vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.会员销售额}}</div>
            <div v-if="pagetype==2" style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.客单价}}</div>
            <div v-if="pagetype==2" style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.客流量}}</div>
          </div>
        </div>
      </div>
    </div>

    <!--电商排名-->
    <div v-if="pagetype==1" style="display:flex;justify-content:center;margin-top:3vh;">
      <div style="background-color:#FFFFFF;width:92vw;">
        <div style="display:flex;align-items:center;margin-left:2vw;height:6vh;">
          <img src="@/assets/icon/mendianpaiming.png" class="option_img">
          <div class="option_title">电商排名</div>
        </div>
        <div style="margin-left:2vw;overflow:scroll;">
          <div style="display:flex;color:#9C9C9C;font-size:3.2vw;margin-bottom:0.5vh;">
            <div style="min-width:10vw;text-align:center;">排名</div>
            <div style="width:30vw;text-align:center;">门店编号</div>
            <div style="width:40vw;text-align:center;">门店名称</div>
            <div style="width:30vw;text-align:center;">销售额</div>
            <div style="width:30vw;text-align:center;">成本额</div>
            <div style="width:30vw;text-align:center;">毛利额</div>
          </div>
          
          <div v-for="(item,index) in table_data4" :key="index" style="display:flex;color:#9C9C9C;font-size:3vw;border-top:1px solid #E2E2E2;">
            <div  v-if="index<table_data4.length-1" style="min-width:6vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{index + 1}}</div>
            <div  v-if="index==table_data4.length-1" style="min-width:6vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;"></div>
            <div style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.门店编号}}</div>
            <div style="width:50vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.门店名称}}</div>
            <div style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.销售额?item.销售额:''}}</div>
            <div style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.成本额}}</div>
            <div style="width:30vw;text-align:center;border-left:1px solid #E2E2E2;min-height:3vh;line-height:3vh;">{{item.毛利额}}</div>
          </div>
        </div>
      </div>
    </div>

    <!--底部tab栏-->
    <Tabbar :choose_index="tab_type" :type="pagetype" :user_type2="user_type2"></Tabbar>
</div>
</template>

<script>
import LineChart from '@/components/LineChart';
import Tabbar from '@/components/Tabbar'
export default {
  components :{
    LineChart,
    Tabbar
  },
  data(){
    return {
      show_tab:true,
      time_item:[
        {name:'今日',icon:require('@/assets/icon/shishi.png'),icon_xuan:require('@/assets/icon/shishi_xuan.png'),value:0},
        {name:'昨日',icon:require('@/assets/icon/zuori.png'),icon_xuan:require('@/assets/icon/zuori_xuan.png'),value:1},
        {name:'一周',icon:require('@/assets/icon/yizhou.png'),icon_xuan:require('@/assets/icon/yizhou_xuan.png'),value:2},
        {name:'当月',icon:require('@/assets/icon/dangyue.png'),icon_xuan:require('@/assets/icon/dangyue_xuan.png'),value:3},
      ],//时间队列
      pagetype:0,//1 为总部看板 2为门店看板
      tab_type:0,//tab栏
      startTime:'',//开始时间
      endTime:'',//结束时间
      table_data1:[],//数据体1
      table_data2:[],//数据体2
      table_data3:[],//数据体3
      table_data4:[],//数据体4 电商排名
      line_data:[],//折现统计图
      time_value:0,//默认显示实时
      img_list:[
        require('@/assets/icon/hanshui.png'),
        require('@/assets/icon/keliu.png'),
        require('@/assets/icon/huiyuan.png'),
        require('@/assets/icon/yingye.png'),
        require('@/assets/icon/hanshuixiao.png'),
        require('@/assets/icon/hanshuimao.png'),
        require('@/assets/icon/keliuliang.png'),
        require('@/assets/icon/kedanjia.png'),
        require('@/assets/icon/zongmaoli.png'),
        require('@/assets/icon/chengben.png'),
      ],
      menu_list1:[],//菜单1
      // menu_list2:[],//菜单2
      loading:'',//loading
      new_show_date:'',//日期
      user_type2:false,//区域账号
      type2shopId:'',//区域id
    }
  },
  watch:{
    // $route(){
    //     this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
    //     this.curve_api();
    //     this.viewmenu_api();
    // }
  },
  mounted(){
    this.tab_type = this.$route.query.pagetype*1;
    console.log(this.tab_type,"o900909090-0-9")
    // this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
    if(JSON.parse(localStorage.getItem("user_info")).type==0){//总部看板
      this.pagetype=1
    }else{//门店看板
      this.pagetype=2
    }
    //区域看板
    if(JSON.parse(localStorage.getItem("user_info")).type==2 || JSON.parse(localStorage.getItem("user_info")).type==4 ||  JSON.parse(localStorage.getItem("user_info")).type==6){//2为区域账号
      this.user_type2 = true;
      this.getShopByArea();
    }else{
      this.init();
    }

  },
  methods:{
    //初始化
    init(){
        this.getShowDate(); //格式化日期
        this.showLoading();
        this.endTime = this.getYYYYMMDD();
        this.startTime = this.getYYYYMMDD();
        this.time_value = 0;
        this.curve_api();
        this.viewmenu_api();
        if(this.pagetype==1){//总部看板获取电商
          this.curve2_api();
        }
    },
    //获取区域详情
    getShopByArea(){
      this.$axios.post(this.$api.getShopByArea,{
        area:JSON.parse(localStorage.getItem("user_info")).shopId
      }).then(res=>{
        this.type2shopId = res.data.data.shopId;
        this.init();
      })
    },
    //获取显示日期
    getShowDate(){
      this.new_show_date = this.getYYYYMMDD();
      // let new_date = new Date(Date.now());
      // let new_tomorrow = new Date(Date.now() - 86400000);
      // this.time_item[0].name = new_date.getDate() + '号';
      // this.time_item[1].name = new_tomorrow.getDate() + '号';
    },
    //loading
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    //时间格式化
    getYYYYMMDD(e){
      let nowDate = ''
      if(e){
        nowDate = new Date(e);
      }else{
        nowDate = new Date();
      }
			let day = nowDate.getDate();
			let month = nowDate.getMonth() + 1;
			let year = nowDate.getFullYear();
			return  year + '-' + month + '-' + day;
    },
    //选择时间
    chooseTime(e){
      this.showLoading();
      this.time_value = e;
      if(e==0){//实时
        this.startTime = this.getYYYYMMDD();
        this.endTime = this.getYYYYMMDD();
      }else if(e==1){//昨日
        this.startTime = this.getYYYYMMDD(Date.now() - 86400000);
        this.endTime = this.getYYYYMMDD(Date.now() - 86400000);
      }else if(e==2){//一周
        this.startTime = this.getYYYYMMDD(Date.now() - 518400000); 
        this.endTime = this.getYYYYMMDD();
      }else if(e==3){//当月
        let nowDate = new Date();
			  let month = nowDate.getMonth() + 1;
			  let year = nowDate.getFullYear();
        this.startTime =  year + '-' + month + '-1';
        this.endTime = this.getYYYYMMDD();
      }
      this.curve_api();
      this.curve2_api();
      this.viewmenu_api();
    },
    //获取电商排名
    curve2_api(){
      this.$axios.post(this.$api.curve2_api,{
        shopId:this.user_type2?this.type2shopId:JSON.parse(localStorage.getItem("user_info")).userId,
        startTime:this.startTime,
        endTime:this.endTime,
        userType:JSON.parse(localStorage.getItem("user_info")).type
      }).then(res=>{
        this.table_data4 = [];
        this.table_data4 = this.table_data4.concat(res.data.data[0],res.data.data[1])
        //对table_data4进行排序
        for(let i=0;i<this.table_data4.length;i++){
          for(let j=0;j<this.table_data4.length;j++){
            if(this.table_data4.length[j]<this.table_data4.length[j+1]){
              let tmp = this.table_data4.length[j];
              this.table_data4.length[j] = this.table_data4.length[j+1];
              this.table_data4.length[j + 1] = tmp;
            }
          }
        }
        //计算总价
        let total = {
            '门店编号':'合计',
            '销售额':0,
            '成本额':0,
            '毛利额':0,
        };
        for(let i=0;i<this.table_data4.length;i++){
          total.销售额 = total.销售额 + this.table_data4[i].销售额*1;
          total.成本额 = total.成本额 + this.table_data4[i].成本额*1;
          total.毛利额 = total.毛利额 + this.table_data4[i].毛利额*1;
        }
        total.销售额 = total.销售额.toFixed(2)*1;
        total.成本额 = total.成本额.toFixed(2)*1;
        total.毛利额 = total.毛利额.toFixed(2)*1;
        this.table_data4.push(total);
      })
    },
    //获取曲线及销售排名数据
    curve_api(){
      let url = this.$api.curve_api;
      let params = {
        userId:JSON.parse(localStorage.getItem("user_info")).userId,
        userType:JSON.parse(localStorage.getItem("user_info")).type,
        startTime:this.startTime,
        endTime:this.endTime,
      }
      if(JSON.parse(localStorage.getItem("user_info")).type!=0){//0为总部看板
        url = this.$api.curve_api + '1'
        if(this.user_type2){//区域id
          params.shopId = this.type2shopId
        }else{
          params.shopId = JSON.parse(localStorage.getItem("user_info")).shopId
        }
      }
      this.$axios.post(url,params).then(res=>{
        this.loading.close();
        this.table_data3 = res.data.data[0];
        //计算合计数据
        if(this.pagetype==1){//1总店看板
            let total = {
            '销售额':0,
            '分支机构':'合计',
            '分支机构编码':'合计'
          };
          for(let i=0;i<this.table_data3.length;i++){
            this.table_data3[i].分支机构编码 = this.table_data3[i].分支机构;
            //去掉前五位
            this.table_data3[i].分支机构 = this.table_data3[i].分支机构.substring(6,this.table_data3[i].分支机构.length)
            total.销售额 = total.销售额*1 + this.table_data3[i].销售额*1;
          }
          total.销售额 = total.销售额.toFixed(2);
          this.line_data = this.table_data3;//折线图
          this.table_data3.push(total);//带上总计
        }else{//2门店看板
          let total = {
            '销售额':0,
            '营业员':'合计',
            '会员销售额':0,
            '客单价':0,
            '客流量':0,
          };
          for(let i=0;i<this.table_data3.length;i++){
            this.table_data3[i].营业员 = this.table_data3[i].营业员;
            total.销售额 = total.销售额*1 + this.table_data3[i].销售额*1;
            total.会员销售额 = total.会员销售额*1 + this.table_data3[i].会员销售额*1;
            total.客单价 = total.客单价*1 + this.table_data3[i].客单价*1;
            total.客流量 = total.客流量*1 + this.table_data3[i].客流量*1;
          }
          total.销售额 = total.销售额.toFixed(2)*1;
          total.会员销售额 = total.会员销售额.toFixed(2)*1;
          total.客单价 = total.客单价.toFixed(2)*1;
          total.客流量 = total.客流量.toFixed(2)*1;
          this.line_data = this.table_data3;//折线图
          this.table_data3.push(total);//带上总计
        }
      })
    },
    //获取看板名字
    viewmenu_api(){
      this.$axios.post(this.$api.viewmenu_api,{
        userType:JSON.parse(localStorage.getItem("user_info")).type
      }).then(res=>{
        //格式化菜单
        let menu_data = res.data.data;
        this.menu_list1 = [];
        // this.menu_list2 = [];
        let every_item = {}
        // for(let i=0;i<4;i++){
        //   every_item = {
        //     name:menu_data[i].view,
        //     sql: menu_data[i].sql
        //   }
        //   this.menu_list1.push(every_item)
        // }
        // for(let i=0;i<5;i++){
        //   every_item = {
        //     name:menu_data[i].view,
        //     sql: menu_data[i].sql
        //   }
        //   this.menu_list2.push(every_item)
        // }
        for(let i=0;i<menu_data.length;i++){
          every_item = {
            name:menu_data[i].view,
            sql: menu_data[i].sql
          }
          this.menu_list1.push(every_item)
        }
        this.view1_api();
      })
    },
    //获取看板数据
    view1_api(){
      this.table_data1 = [];
      this.table_data2 = [];
      for(let i=0;i<this.menu_list1.length;i++){
          let query = {
              userId:JSON.parse(localStorage.getItem("user_info")).userId,
              userType:JSON.parse(localStorage.getItem("user_info")).type,
              shopId:this.user_type2?this.type2shopId:JSON.parse(localStorage.getItem("user_info")).shopId,
              startTime:this.startTime,
              endTime:this.endTime,
              sql:this.menu_list1[i].sql
          }
          // if(this.pagetype==2){
           //   query.type = 1;
           //   query.shopId = JSON.parse(localStorage.getItem("user_info")).shopId;
           // }
           this.$axios.post(this.$api.view1_api+(i+1),query).then(res=>{
            let every_data = res.data.data;
            if(!every_data.length){
              return false;
            }
            if(this.pagetype==1){//总部两个数据源合并
                if(every_data[0]&&every_data[1]){//若两个都存在的情况
                  let every_name = this.menu_list1[i].name;
                  every_data[0][every_name] = every_data[0][every_name] + every_data[1][every_name];
                }else if(every_data[1]){
                  every_data[0][every_name] = every_data[1][every_name];
                }
            }

             for(let k=0;k<this.menu_list1.length;k++){
               if(this.menu_list1[k].name == Object.keys(every_data[0])[0]){
                  let every_item = {
                    name:Object.keys(every_data[0])[0],
                    value:Object.values(every_data[0])[0],
                    item_index:k,
                    img:this.img_list[k]
                  };
                  if(k<4){
                      this.table_data1[k] = every_item
                  }else{
                      this.table_data2[k-4] = every_item
                      // this.table_data2.push(every_item);
                  }
                  this.table_data1.push(',');
                  this.table_data1.pop();
               }
             }
           })
      }
    }
  }
}
</script>
<style scoped>
.title_div{
  height:9vh;
  background-image: linear-gradient(#04A9F3, #4EC5FB);
  color:#FFFFFF;
  /* line-height:12vh;
  text-align: center;
  font-size:4.6vw; */
}
.every_option1{
  height:8vh;
  width: 23vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.every_time{
  height: 5vh;
  width: 23vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_img{
  height:5vw;
  width:5vw;
  margin-right: 1.5vw;
}
.option_title{
  font-size:4vw;
  font-weight: 800;
  color:#000000;
}
.every_option2{
  width: 30vw;
  background-color: #FFFFFF;
  margin-top:0.8vh;
}
.option2_font{
  height:4vh;
  line-height:5vh;
  /* font-size:1vw; */
  transform: scale(0.8);
  width:34vw;
  margin-left:-2vw;
  color:#898989;
}
.option2_font2{
  font-size:3vw;
  color:#898989;
  margin-left:1vw;
}
.ranking_icon{
  height:3.6vw;
  width:4vw;
}
.time_choose{
  height:5.4vw;
  line-height:5.4vw;
  font-size:3.6vw;
  margin-left:1.6vw;
}
.line_body{
  height:23vh;
  background-color:#FFFFFF;
  overflow:scroll;
}
</style>
